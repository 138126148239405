import { Switch, Route } from "react-router-dom";
import Home from "../../components/Home/Home";
import Vision from "../../components/Vision/Vision";
import Contact from "../../components/ContactUs/Contact";
import Header from "../../components/Header/Header";
import Post from "../../components/Post/Post";
import NotFound from "../../components/404/NotFound";
import About from "../../components/AboutUs/About";
import UserGallery from "../../components/Gallery/UserGallery";
import Service from "../../components/Services/Service";
import Latest from "../../components/latest/latest";
import StaffInfo from "../../components/Staff-Info/StaffInfo";
import Facilities from "../../components/Facilities/Facilities";
import AllPdfs from "../../components/AllPdfs/AllPdfs"

const Main = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        <Header />
        <Home />
      </Route>
      <Route exact path="/Home">
        <Header />
        <Home />
      </Route>
      <Route path="/About">
        <About />
      </Route>
      <Route path="/vision">
        <Vision />
      </Route>
      <Route path="/Contact">
        <Contact />
      </Route>
      <Route path="/Gallery">
        <UserGallery />
      </Route>
      <Route path="/post/:id">
        <Post />
      </Route>
      <Route path="/service">
        <Service />
      </Route>
      <Route path="/all-news">
        <Latest />
      </Route>
      <Route path="/all-pdfs">
        <AllPdfs />
      </Route>
      <Route path="/staff-info">
        <StaffInfo />
      </Route>
      <Route path="/facilities">
        <Facilities />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Main;
