import { useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    useEffect(() => {
        document.title = "Page Not Found | CM RISE Govt School Indore";
    }, []);

    return (
        <div className="mt-4 mb-4 text-center mx-auto">
            <h1>Not Found</h1>
            <p>Konnichiwa 💁🏻</p>
            <Link to="/">Let's get back on the right path</Link>
        </div>
    );
};

export default NotFound;
