import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Vision.module.css";
import {
  vision,
  visionPoint,
  mission,
  missionPoint,
  imgs,
} from "../../data/visi";
import VisiImg from "./VisiImg";

const Vision = () => {
  let contextJustify = `mb-4 pb-3 ${styles.contextJustify}`;
  useEffect(() => {
    document.title = "Vision & Mission | CM RISE Govt School Indore";
  }, []);

  const missionPointList = missionPoint.map((item) => {
    return <li key={item.id}>{item.content}</li>;
  });

  const visionPointList = visionPoint.map((item) => {
    return <li key={item.id}>{item.content}</li>;
  });

  return (
    <>
      <div className={`mb-5 ${styles.Bg}`}>
        <Container>
          <Row className="pt-4 text-light">
            <Col className={`${styles.Bottom}`}>
              <h1
                className={`
                                ${styles.Head} 
                                ${styles.Animated} 
                                ${styles.AnimatedFadeInUp} 
                                ${styles.FadeInUp}`}
              >
                Vision and Mission
              </h1>
              <h1
                className={`
                                font-weight-bold 
                                ${styles.AnimatedChild} 
                                ${styles.AnimatedFadeInUp} 
                                ${styles.FadeInUp} 
                                ${styles.TitleFont}`}
              >
                CM RISE Govt Ahilya Ashram School No 1 Indore
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mb-4 pb-3">
          <Col sm="8" className="d-flex align-items-center">
            <div className={styles.MarginRight}>
              <h4 className="font-weight-bold mb-4">Vision</h4>
              <p className={contextJustify}>"{vision}"</p>
              <ol className={contextJustify}>{visionPointList}</ol>
            </div>
          </Col>
          <Col sm="4">
            <VisiImg imgA={imgs[0]} />
          </Col>
        </Row>

        <Row>
          <Col sm="8" className="d-flex align-items-center order-1">
            <div className={styles.MarginLeft}>
              <h4 className="font-weight-bold mb-4">Mission</h4>
              <p className={contextJustify}>"{mission}"</p>
              <ol className={contextJustify}>{missionPointList}</ol>
            </div>
          </Col>
          <Col
            sm="4"
            className="order-2 order-sm-first d-flex align-items-center"
          >
            <div>
              <VisiImg imgA={imgs[1]} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Vision;
