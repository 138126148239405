import rameshKumarImage from "../assets/staff_pic/rameshkumarsen.jpg";
import vipinjainImage from "../assets/staff_pic/vipinkumarjain.jpg";
import ashokkumarjainImage from "../assets/staff_pic/ashok_kumar_jain.jpg";
import rajshrimudris from "../assets/staff_pic/rajsri_mudris.jpg";
import gopalkrishnavani from "../assets/staff_pic/gopalkrishna_vani.jpg";
import sanjayshukla from "../assets/staff_pic/dummypic.jpg";
import sangeetabhanodiya from "../assets/staff_pic/sangeeta_banoriya.jpg";

import varshasharma from "../assets/staff_pic/varsha_sharma.jpg";
import shakshiasahare from "../assets/staff_pic/sakhi_sahare.jpg";
import meekashivajei from "../assets/staff_pic/dummyfemal.jpg";
import anjugoutam from "../assets/staff_pic/anju_goyal.jpg";
import rajeshsrivastwa from "../assets/staff_pic/rajesh_srivastava.jpg";
import ajitkumar from "../assets/staff_pic/ajit_kumar_pandey.jpg";
import neelukohli from "../assets/staff_pic/neelu_kohli.jpg";
import niveditabhargwa from "../assets/staff_pic/dummyfemal.jpg";
import meenagoyal from "../assets/staff_pic/meena_goyal.jpg";
import sunitakarwadiya from "../assets/staff_pic/sunita_karwadiya.jpg";

import varshasaxena from "../assets/staff_pic/dummyfemal.jpg";
import rakeshkumarvairagi from "../assets/staff_pic/dummypic.jpg";
import rajnishsarotiya from "../assets/staff_pic/rajnish_sarotiya.jpg";
import anitamisra from "../assets/staff_pic/anita_mishra.jpg";
import preetijain from "../assets/staff_pic/preeti_jain.jpg";
import deebasyed from "../assets/staff_pic/deeba_syyed.jpg";

import vandanadewda from "../assets/staff_pic/vandana_dewda.jpg";
import sunitakandhari from "../assets/staff_pic/dummyfemal.jpg";
import ashisshrma from "../assets/staff_pic/dummypic.jpg";
import viashalitiwari from "../assets/staff_pic/vaishali_tiwari.jpg";
import bhagirathpatak from "../assets/staff_pic/dummypic.jpg";
import meenamalviya from "../assets/staff_pic/meena_malviya.jpg";
import manojkumari from "../assets/staff_pic/manoj_kumar.jpg";
import sonalgupta from "../assets/staff_pic/sonal_gupta.jpg";
import jyotimahawar from "../assets/staff_pic/jyoti_mahawar.jpg";
import sudhadtrivedi from "../assets/staff_pic/dummyfemal.jpg";
import bhartisharma from "../assets/staff_pic/bharati_sharma.jpg";
import needhisarotiya from "../assets/staff_pic/dummyfemal.jpg";
import sangeetakumari from "../assets/staff_pic/sangeeta_kumari.jpg";
import sumanjain from "../assets/staff_pic/suman_jain.jpg";
import prashistigargav from "../assets/staff_pic/dummyfemal.jpg";
import aartisharma from "../assets/staff_pic/arti_sharma.jpg";
import govidkumarsahrma from "../assets/staff_pic/govind_kumar_sharma.jpg";
import ankitamisra from "../assets/staff_pic/ankita_mishra.jpg";

import pankajbhaswar from "../assets/staff_pic/dummypic.jpg";
import gayatrishukla from "../assets/staff_pic/gayatrishukla.jpeg";
import atulkumartiwari from "../assets/staff_pic/atul_kumar.jpg";
import srutisamiya from "../assets/staff_pic/dummyfemal.jpg";
import chadansinghmadloi from "../assets/staff_pic/chandan_singh_mandloi.jpg";
import navneetbhaoya from "../assets/staff_pic/dummypic.jpg";
import poojachouhan from "../assets/staff_pic/pooja_chouhan.jpg";
import kiranyadav from "../assets/staff_pic/kiran_yadav.jpg";
import sakhijain from "../assets/staff_pic/sakshi_jain.jpg";
import aasishjaiswal from "../assets/staff_pic/aashish_jaiswal.jpg";
import goldikarare from "../assets/staff_pic/dummyfemal.jpg";
import neharamanani from "../assets/staff_pic/neha_ramnani.jpg";
import bhawananinya from "../assets/staff_pic/bhavna_aaniya.jpg";

const article = [
  {
    id: 1,
    name: "RAMESH KUMAR SEN",
    position: "Principal",
    img: rameshKumarImage,
    subject: "Physics",
  },
  {
    id: 2,
    name: "VIPIN JAIN",
    position: "Vice Principal",
    img: vipinjainImage,
    subject: "Hindi",
  },
  {
    id: 3,
    name: "ASHOK KUMAR JAIN",
    position: "Principal (Middle Section) ",
    img: ashokkumarjainImage,
    subject: "Botany",
  },
  {
    id: 4,
    name: "RAJSHRI MUDRIS",
    position: "Assistant Teacher",
    img: rajshrimudris,
    subject: "EVS",
  },
  // {
  //   id: 5,
  //   name: "SEEMA AGRAWAL",
  //   position: "Lecturer",
  //   img: seemaagrawal,
  //   subject: "English",
  // },
  {
    id: 6,
    name: "GOPALKRISHNA VANI",
    position: "Lecturer",
    img: gopalkrishnavani,
    subject: "Mathmetics",
  },
  {
    id: 7,
    name: "SANJAY SHUKLA",
    position: "Higher Secondary Teacher",
    img: sanjayshukla,
    subject: "Physics",
  },
  {
    id: 8,
    name: "SANGEETA BHANODIA",
    position: "Higher Secondary Teacher",
    img: sangeetabhanodiya,
    subject: "BioLogy",
  },
  // {
  //   id: 9,
  //   name: "SHOBHA JALWANIYA",
  //   position: "Primary Teacher",
  //   img: shobhajalwaniya,
  //   subject: "EVS",
  // },
  {
    id: 10,
    name: "Dr. VARSHA SHARMA",
    position: "Higher Secondary Teacher",
    img: varshasharma,
    subject: "Hindi",
  },
  {
    id: 11,
    name: "SAKSHI SAHARE",
    position: "Assistant Professor",
    img: shakshiasahare,
    subject: "Science",
  },
  {
    id: 12,
    name: "MENAKSHI BAJPAI",
    position: "Secondary Teacher",
    img: meekashivajei,
    subject: "Social Science",
  },
  {
    id: 13,
    name: "ANJU GOUTAM",
    position: "Higher Secondary Teacher",
    img: anjugoutam,
    subject: "Hindi",
  },
  {
    id: 14,
    name: "RAJESH SHRIVASTAVA",
    position: "Higher Secondary Teacher",
    img: rajeshsrivastwa,
    subject: "English",
  },
  {
    id: 15,
    name: "AJIT KUMAR PANDEY",
    position: "Higher Secondary Teacher",
    img: ajitkumar,
    subject: "English",
  },
  {
    id: 16,
    name: "NEELU KOHLI",
    position: "Higher Secondary Teacher",
    img: neelukohli,
    subject: "Chemistry",
  },
  {
    id: 17,
    name: "NIVEDITA BHARGAVA",
    position: "Higher Secondary Teacher",
    img: niveditabhargwa,
    subject: "Biology",
  },
  {
    id: 18,
    name: "Dr. MEENA GOYAL",
    position: "Primary Teacher",
    img: meenagoyal,
    subject: "EVS",
  },
  {
    id: 19,
    name: "SUNITA KARWADIYA",
    position: "Assistant Teacher",
    img: sunitakarwadiya,
    subject: "EVS",
  },
  // {
  //   id: 20,
  //   name: "RACHANA SABARWAL",
  //   position: "Assistant Teacher",
  //   img: rachnasabrawal,
  //   subject: "EVS",
  // },
  {
    id: 21,
    name: "VARSHA SAXENA",
    position: "Secondary Teacher",
    img: varshasaxena,
    subject: "Mathmatics",
  },
  {
    id: 22,
    name: "RAKESH KUMAR VAIRAGI",
    position: "Secondary Teacher",
    img: rakeshkumarvairagi,
    subject: "Sanskrit",
  },
  {
    id: 23,
    name: "RAJNISH SHROTRIYA",
    position: "Secondary Teacher",
    img: rajnishsarotiya,
    subject: "Science",
  },

  {
    id: 24,
    name: "ANITA MISHRA",
    position: "Secondary Teacher",
    img: anitamisra,
    subject: "Social Science",
  },
  {
    id: 25,
    name: "PREETI JAIN",
    position: "Secondary Teacher",
    img: preetijain,
    subject: "Science",
  },
  {
    id: 26,
    name: "DEEBA SYYED",
    position: "Secondary Teacher",
    img: deebasyed,
    subject: "Social Science",
  },
  // {
  //   id: 27,
  //   name: "INDER SINGH NAGAR",
  //   position: "Primary Teacher",
  //   img: indersinghnagar,
  //   subject: "EVS",
  // },
  {
    id: 28,
    name: "VANDANA DEWDA",
    position: "Primary Teacher",
    img: vandanadewda,
    subject: "EVS",
  },
  {
    id: 29,
    name: "SUNITA KANDHARI",
    position: "Secondary Teacher",
    img: sunitakandhari,
    subject: "Social Science",
  },
  {
    id: 30,
    name: "Dr. ASHEESH SHARMA",
    position: "Higher Secondary Teacher",
    img: ashisshrma,
    subject: "English",
  },
  {
    id: 31,
    name: "VAISHALI TIWARI",
    position: "Higher Secondary Teacher",
    img: viashalitiwari,
    subject: "Biology",
  },
  {
    id: 32,
    name: "BHAGIRATH PATHAK",
    position: "Higher Secondary Teacher",
    img: bhagirathpatak,
    subject: "Sanskrit",
  },
  {
    id: 33,
    name: "MEENA MALVIYA",
    position: "Primary Teacher",
    img: meenamalviya,
    subject: "EVS",
  },
  {
    id: 34,
    name: "MANOJ KUMARI",
    position: "Higher Secondary Teacher",
    img: manojkumari,
    subject: "Commerce",
  },
  {
    id: 35,
    name: "SONAL GUPTA",
    position: "Assistant Professor",
    img: sonalgupta,
    subject: "Mathmatics",
  },
  {
    id: 36,
    name: "JYOTI MAHAWAR",
    position: "Higher Secondary Teacher",
    img: jyotimahawar,
    subject: "Hindi",
  },
  {
    id: 37,
    name: "Dr. SUDHA DWIVEDY",
    position: "Higher Secondary Teacher",
    img: sudhadtrivedi,
    subject: "Political Science",
  },
  {
    id: 38,
    name: "BHARATI SHARMA",
    position: "Higher Secondary Teacher",
    img: bhartisharma,
    subject: "Hindi",
  },
  {
    id: 39,
    name: "NIDHI SHROTRIYA",
    position: "Secondary Teacher",
    img: needhisarotiya,
    subject: "Mathmatics",
  },
  {
    id: 40,
    name: "SANGEETA KUMARI",
    position: "Higher Secondary Teacher",
    img: sangeetakumari,
    subject: "History",
  },
  {
    id: 41,
    name: "SUMAN JAIN",
    position: "Higher Secondary Teacher",
    img: sumanjain,
    subject: "Hindi",
  },
  {
    id: 42,
    name: "PRASHASTI GARGAV",
    position: "Secondary Teacher",
    img: prashistigargav,
    subject: "English",
  },
  {
    id: 43,
    name: "ARTI SHARMA",
    position: "Higher Secondary Teacher",
    img: aartisharma,
    subject: "Economics",
  },
  {
    id: 44,
    name: "GOVIND KUMAR SHARMA",
    position: "Higher Secondary Teacher",
    img: govidkumarsahrma,
    subject: "Physics",
  },
  {
    id: 45,
    name: "ANKITA MISHRA",
    position: "Higher Secondary Teacher",
    img: ankitamisra,
    subject: "Chemistry",
  },
  // {
  //   id: 46,
  //   name: "PRANAV KUMAR VATS",
  //   position: "Higher Secondary Teacher",
  //   img: pranavkumarvats,
  //   subject: "Chemistry",
  // },
  {
    id: 47,
    name: "PANKAJ BHAWSAR",
    position: "Higher Secondary Teacher",
    img: pankajbhaswar,
    subject: "Mathmatics",
  },
  {
    id: 48,
    name: "GAYTRI SHUKLA",
    position: "Assistant Professor",
    img: gayatrishukla,
    subject: "Social Science",
  },
  {
    id: 49,
    name: "ATUL KUMAR TIWARI",
    position: "Librarian",
    img: atulkumartiwari,
    subject: "EVS",
  },
  {
    id: 50,
    name: "SHRUTI SAMAIYA",
    position: "Secondary Teacher",
    img: srutisamiya,
    subject: "English",
  },
  {
    id: 51,
    name: "CHANDAN SINGH MANDLOI",
    position: "Assistant Teacher",
    img: chadansinghmadloi,
    subject: "EVS",
  },
  {
    id: 52,
    name: "NAVNEET BARODIYA",
    position: "Music Teacher",
    img: navneetbhaoya,
    subject: "Music",
  },
  {
    id: 53,
    name: "POOJA CHOUHAN",
    position: "Higher Secondary Teacher",
    img: poojachouhan,
    subject: "Chemistry",
  },
  {
    id: 54,
    name: "KIRAN YADAV",
    position: "Secondary Teacher",
    img: kiranyadav,
    subject: "Science",
  },
  {
    id: 55,
    name: "SAKSHI JAIN",
    position: "Higher Secondary Teacher",
    img: sakhijain,
    subject: "English",
  },
  {
    id: 56,
    name: "AASHISH JAISWAL",
    position: "Assistant Grad 3",
    img: aasishjaiswal,
    subject: "EVS",
  },
  {
    id: 57,
    name: "GOLDI KARARE",
    position: "Higher Secondary Teacher",
    img: goldikarare,
    subject: "Sociology",
  },
  {
    id: 58,
    name: "NEHA RAMNANI",
    position: "Higher Secondary Teacher",
    img: neharamanani,
    subject: "Commerce",
  },
  {
    id: 59,
    name: "BHAWNA AANIYA",
    position: "Higher Secondary Teacher",
    img: bhawananinya,
    subject: "Commerce",
  },
];

export default article;
