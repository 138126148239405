import React, { useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { BiCalendar } from "react-icons/bi";

import postData from "../../data/article";
import NotFound from "../404/NotFound";
import styles from "./Post.module.css";

const Post = () => {
  const { id } = useParams();
  const history = useHistory();
  let selectedPost = postData.filter((post) => post.id === parseInt(id));

  useEffect(() => {
    let title = "Halaman tidak ada";
    if (selectedPost.length !== 0) {
      title = selectedPost[0].title;
    }
    document.title = title;
  }, [selectedPost]);

  const clickHandler = () => {
    history.push("/");
  };

  if (selectedPost.length === 0) {
    return <NotFound />;
  }

  const isi = selectedPost[0].content.split("\n").map((item, idx) => {
    return (
      <div key={idx} className="mb-3">
        {item}
        <br />
      </div>
    );
  });

  const otherNews = postData.map((post) => {
    return (
      <li key={post.id}>
        <Link to={`/post/${post.id}`}>{post.title}</Link>
      </li>
    );
  });

  return (
    <>
      <div className={`${styles.Fill}`}>
        <img src={selectedPost[0].img} alt="post img" />
        <h2
          className={`${styles.Title} ${styles.Animated} ${styles.AnimatedFadeInUp} ${styles.FadeInUp}`}
        >
          {selectedPost[0].title}
        </h2>
      </div>
      <Container className={`mt-4 mb4 pb-4 mx-auto`}>
        <Row>
          <Col md={8} className="mx-auto">
            <Breadcrumb>
              <Breadcrumb.Item onClick={clickHandler}>Home</Breadcrumb.Item>
              <Breadcrumb.Item active>News</Breadcrumb.Item>
              <Breadcrumb.Item active>{selectedPost[0].title}</Breadcrumb.Item>
            </Breadcrumb>

            {isi}
            <p className="mt-3 mb-0">
              <BsPencilSquare className="pb-1" />
              Writer: {selectedPost[0].writer}
            </p>
            <p className="mb-4 pb-3">
              <BiCalendar className="pb-1" />
              On: {selectedPost[0].date.toDateString()}
            </p>
          </Col>

          <Col md={4} className="mx-auto">
            <Breadcrumb>
              <Breadcrumb.Item active>Other News</Breadcrumb.Item>
            </Breadcrumb>

            <ul>{otherNews}</ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Post;
