import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";

import logo from "../../assets/img/mylogo.webp";
import styles from "./NavBar.module.css";
import { Analytics } from "@vercel/analytics/react";

const NavBar = () => {
  const [openToggle, setOpenToggle] = useState(false);

  const toggleHandler = () => setOpenToggle(!openToggle);
  const closeNavHandler = () => setOpenToggle(false);

  return (
    <Navbar
      expand="lg"
      className={`pt-2 ${styles.BlurBackground}`}
      variant="light"
      expanded={openToggle}
      sticky="top"
    >
      <Container>
        <Link to="/">
          <Navbar.Brand onClick={closeNavHandler}>
            <img
              src={logo}
              height={40}
              className={`d-inline-block align-top pr-2 ${styles.BorderRight}`}
              alt="React Bootstrap logo"
            />
            <span>
              <div className={`${styles.Title} ${styles.Top}`}>
                CM RISE Govt. Ahilya Ashram &
              </div>
              <div className={`${styles.Title} ${styles.Bottom}`}>
                Chandravati Girls School No.1 Indore
              </div>
            </span>
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle
          onClick={toggleHandler}
          className={styles.Toggle}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto font-weight-bold">
            <Nav.Link
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
              href="/home"
              onClick={closeNavHandler}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
              href="/about"
              onClick={closeNavHandler}
            >
              About Us
            </Nav.Link>
            <Nav.Link
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
              href="/vision"
              onClick={closeNavHandler}
            >
              Vision & Mission
            </Nav.Link>
            <Nav.Link
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
              href="/facilities"
              onClick={closeNavHandler}
            >
              Facilities
            </Nav.Link>
            {/* <Nav.Link
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
              href="/gallery"
              onClick={closeNavHandler}
            >
              Gallery
            </Nav.Link> */}

            <Nav.Link
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
              href="/contact"
              onClick={closeNavHandler}
            >
              Contact Us
            </Nav.Link>

            <NavDropdown
              title="Gallery"
              id="basic-nav-dropdown"
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
            >
              <NavDropdown.Item
                className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                href="/gallery"
              >
                Photos
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                href="/all-pdfs"
              >
                PDF'S
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Services"
              id="basic-nav-dropdown"
              className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
            >
              <NavDropdown.Item
                className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                href="/staff-info"
              >
                Staff Information
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                href="/all-news"
              >
                All News
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                href="/service"
              >
                Download Forms
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Analytics />
      </Container>
    </Navbar>
  );
};

export default NavBar;
