import image1 from "../assets/img/about1.jpg";
import image2 from "../assets/img/about2.jpg";
import image3 from "../assets/img/about3.jpg";
import image4 from "../assets/img/about4.jpg";
import image5 from "../assets/img/about5.jpg";

const profil = [
    {
        id: 1,
        content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    },
    // {
    //     id: 2,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 3,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 4,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 5,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 6,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 7,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 8,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 9,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // },
    // {
    //     id: 10,
    //     content: "The Government Ahilya Ashram Girls Higher Secondary School No. 1, located in Indore, Madhya Pradesh, has a rich history of providing education to girls in the region. The school is named after Ahilyabai Holkar, the revered queen of the Holkar dynasty who ruled Indore during the 18th century. The institution was established with the aim of empowering girls through education and equipping them with the knowledge and skills necessary for their personal and professional growth. Over the years, the school has played a significant role in promoting female education and has become a respected center for learning in Indore."
    // }
];

const imgs = [
  image1,
  image2,
  image3,
  image4,
  image5
];

export {profil, imgs};