import visionImage1 from "../assets/img/vision1.jpg";
import visionImage2 from "../assets/img/vision2.jpg";

const vision =
  "To be a premier educational institution that nurtures the holistic development of students, empowering them to become compassionate, innovative, and responsible global citizens.";
const visionPoint = [
  {
    id: 1,
    content:
      "Excellence in Education: The school aspires to be a premier institution known for providing high-quality education that meets rigorous academic standards. It focuses on creating an environment that encourages intellectual growth, critical thinking, and a love for learning.",
  },
  {
    id: 2,
    content:
      "Holistic Development: The school aims to nurture the holistic development of students, focusing on their intellectual, physical, social, emotional, and spiritual growth. It strives to create a safe and supportive learning environment that fosters personal growth and well-being.",
  },
  {
    id: 3,
    content:
      "Global Citizenship: The school aims to develop students into responsible global citizens who are aware of their role in the local and global community. It promotes values such as respect, empathy, and compassion, encouraging students to contribute to positive change.",
  },
  {
    id: 4,
    content:
      "Innovation and Creativity: The school aims to foster creativity, innovation, and a growth mindset among students. It encourages students to think critically, solve problems, and adapt to change, preparing them for success in a dynamic world.",
  },
  {
    id: 5,
    content:
      "Social Responsibility: The school aims to instill a sense of social responsibility in students, encouraging them to contribute to the community and make a positive impact on society. It promotes values such as integrity, respect, and empathy, emphasizing the importance of ethical behavior.",
  },
];
const mission =
  "Our mission is to provide a nurturing and inclusive learning environment that fosters academic excellence, character development, and lifelong learning. We are committed to empowering our students to become compassionate, responsible, and globally-minded individuals, equipped with the skills and values to thrive in a dynamic world.";

const missionPoint = [
  {
    id: 1,
    content:
      "Academic Excellence: The school is dedicated to providing a high-quality education that promotes academic rigor, critical thinking, and a strong foundation in core subjects. It aims to prepare students for higher education and professional success.",
  },
  {
    id: 2,
    content:
      "Character Development: The mission emphasizes the importance of character development, emphasizing values such as integrity, respect, empathy, and responsibility. The school aims to cultivate a positive school culture that fosters personal growth, ethical behavior, and social-emotional well-being.",
  },
  {
    id: 3,
    content:
      "Inclusive Learning Environment: The school is committed to creating an inclusive and supportive learning environment that embraces diversity, respects individual differences, and ensures equal opportunities for all students. It values collaboration, open communication, and mutual respect among students, staff, and parents.",
  },
  {
    id: 4,
    content:
      "Lifelong Learning: The mission recognizes the importance of instilling a love for learning and a growth mindset in students. It aims to foster curiosity, creativity, and a lifelong passion for acquiring knowledge, enabling students to adapt and thrive in an ever-evolving world.",
  },
  {
    id: 5,
    content:
      "Global Perspective: The mission emphasizes the development of global citizenship among students. It promotes cultural understanding, appreciation for diversity, and a sense of responsibility towards local and global communities. The school encourages students to engage with global issues, think critically, and actively contribute to positive change.",
  },
];

const imgs = [
  visionImage1,
  visionImage2  
];

export { vision,visionPoint,  mission, missionPoint, imgs };
