import { Container, Row, Col } from "react-bootstrap";
import styles from "./FacilitiesParsial.module.css";

const FacilitiesParsial = ({ type, titleA, titleB, postA, postB, imgSrc }) => {
  let classesA = "d-flex align-items-center";
  let classesB = `${styles.DivImg}`;
  let paddingText = `${styles.PaddingRight}`;
  let contextJustify = `mt-4 pt-3 ${styles.contextJustify}`;
  let contextJustify1 = `mb-4 pb-3 ${styles.contextJustify}`;

  if (type === 2) {
    classesA = "order-1 d-flex align-items-center";
    classesB = `order-2 order-md-first ${styles.DivImg}`;
    paddingText = `${styles.PaddingLeft}`;
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md={6} className={classesA}>
            <div className={paddingText}>
              <h4 className="font-weight-bold mb-4">{titleA}</h4>

              <p className={contextJustify}>{postA}</p>

              <h4 className="font-weight-bold mb-4">{titleB}</h4>

              <p className={contextJustify1}>{postB}</p>
            </div>
          </Col>
          <Col md={6} className={classesB}>
            <img src={imgSrc} alt="foto profil" />
          </Col>
        </Row>
        <div id={styles.DisplayScreen}>
          <p className="mb-0 pb-3 pt-2 pr-2 text-dark">
            <small>*The above picture is of the proposed building</small>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default FacilitiesParsial;
