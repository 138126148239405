import { useEffect, useRef } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./Contect.module.css";
import { FaPhoneAlt, FaMailBulk, FaAddressCard } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

const Contact = () => {
  const notify = () => toast("Sent Successfully !!");
  const scriptUrl = "https://sheetdb.io/api/v1/cngj393ybs8nz";
  const formRef = useRef(null);
  const handleSubmit = (e) => {
    const formEle = document.querySelector("form");
    e.preventDefault();
    fetch(scriptUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: [
          {
            name: formEle[0].value,
            email: formEle[1].value,
            message: formEle[2].value,
            date: Date().toLocaleString()
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    if (formRef.current) {
      formRef.current.reset();
      notify();
    }
  };

  useEffect(() => {
    document.title = "Contact Us | CM RISE Govt School Indore";
  }, []);

  return (
    <>
      <div>
        <h2 className="font-weight-bold text-center mt-3">Contact Us</h2>
        <div className={styles.Dots}></div>
      </div>
      <Container>
        <Row>
          <Col md={6}>
            <Form
              onSubmit={(e) => handleSubmit(e)}
              className="form"
              ref={formRef}
            >
              <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="name" placeholder="Enter Name" />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email" />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formGridMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Message"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col md={6}>
            <Row>
              <Col className="mt-4 mb-4">
                <h5 className="mb-0">
                  {" "}
                  <FaPhoneAlt /> 0731-2420366
                </h5>
                <h5 className="mb-0">
                  {" "}
                  <FaMailBulk /> acmhss01@gmail.com
                </h5>
                <h5 className="mb-0">
                  {" "}
                  <FaAddressCard /> CM RISE Govt Ahilya Ashram & Chandravati
                  Girls Higher Secondary School No. 1, Pologround Industrial
                  Estate, Indore, Madhya Pradesh 452015.
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <br></br>
        <Row className="mb-4">
          <Col>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.828214008234!2d75.85767947503284!3d22.734625579376576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd70a4c288a7%3A0x1edd2e5adfab5d6b!2sGovernment%20Ahilya%20Ashram%20Girls%20Higher%20Secondry%20School%20no.1!5e0!3m2!1sen!2sin!4v1688202795143!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ backgroundColor: "gray" }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          toastOptions={{
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
          }}
        />
      </div>
    </>
  );
};

export default Contact;
