import { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styles from "../Home/Home.module.css";
import { Link } from "react-router-dom";
import data from "../../data/article";

const Latest = () => {
  useEffect(() => {
    document.title = "All News | CM RISE Govt School Indore";
  }, []);

  let articles = data
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((post) => {
      return (
        <Col sm={4} className="mt-4" key={post.id}>
          <Link to={"/post/" + post.id} className={styles.HovText}>
            <Card border="light" className={`${styles.Hov}`}>
              <Card.Img
                className={styles.CardImg}
                variant="top"
                src={post.img}
              />
              <Card.Body>
                <Card.Title className="font-weight-bold text-dark">
                  {post.title}
                </Card.Title>
                <p className="card-text">
                  <small className={`text-muted`}>
                    {post.date.toDateString()}
                  </small>
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      );
    });

  return (
    <Container>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="font-weight-bold text-center mt-3">All News</h2>
          <div className={styles.Dots}></div>
        </Col>
      </Row>
      <Row className="mb-4 pb-4">{articles}</Row>
    </Container>
  );
};

export default Latest;
