import { Image } from "react-grid-gallery";
import firstImage from "../assets/gallery_Image/schoolImage (1).jpg";
import secondImage from "../assets/gallery_Image/schoolImage (2).jpg";
import thirdImage from "../assets/gallery_Image/schoolImage (3).jpg";
import fourthImage from "../assets/gallery_Image/schoolImage (4).jpg";
import fifthImage from "../assets/gallery_Image/schoolImage (5).jpg";
import sixthImage from "../assets/gallery_Image/schoolImage (6).jpg";
import seventhImage from "../assets/gallery_Image/schoolImage (7).jpg";
import eighthImage from "../assets/gallery_Image/schoolImage (8).jpg";
import ninthImage from "../assets/gallery_Image/schoolImage (9).jpg";
import tenthImage from "../assets/gallery_Image/schoolImage (10).jpg";
import twelvethImage from "../assets/gallery_Image/schoolImage (12).jpg";
import thirteenthImage from "../assets/gallery_Image/schoolImage (13).jpg";
import fourteenthImage from "../assets/gallery_Image/schoolImage (14).jpg";
import fifteenthImage from "../assets/gallery_Image/schoolImage (15).jpg";
import sixteenthImage from "../assets/gallery_Image/schoolImage (16).jpg";
import seventeenthImage from "../assets/gallery_Image/schoolImage (17).jpg";
import eighteenthImage from "../assets/gallery_Image/schoolImage (18).jpg";
import nineteenthImage from "../assets/gallery_Image/schoolImage (19).jpg";
import twentiethImage from "../assets/gallery_Image/schoolImage (20).jpg";
import twentyFirstImage from "../assets/gallery_Image/schoolImage (21).jpg";
import twentySecondImage from "../assets/gallery_Image/schoolImage (22).jpg";
import twentyThirdImage from "../assets/gallery_Image/schoolImage (23).jpg";
import twentyFourthImage from "../assets/gallery_Image/schoolImage (24).jpg";
import twentyFifthImage from "../assets/gallery_Image/schoolImage (25).jpg";
import twentySixthImage from "../assets/gallery_Image/schoolImage (26).jpg";
import twentySeventhImage from "../assets/gallery_Image/schoolImage (27).jpg";
import twentyEighthImage from "../assets/gallery_Image/schoolImage (28).jpg";
import twentyNinthImage from "../assets/gallery_Image/schoolImage (29).jpg";
import thirtySecondImage from "../assets/gallery_Image/schoolImage (32).jpg";
import thirtyThirdImage from "../assets/gallery_Image/schoolImage (33).jpg";
import thirtyFourthImage from "../assets/gallery_Image/schoolImage (34).jpg";
import thirtyFivehImage from "../assets/gallery_Image/schoolImage (35).jpg";
import thirtySixImage from "../assets/gallery_Image/schoolImage (36).jpg";
import thirtySevenImage from "../assets/gallery_Image/schoolImage (37).jpg";
import thirtyEightImage from "../assets/gallery_Image/schoolImage (38).jpg";

import fourtyImage from "../assets/gallery_Image/schoolImage (40).jpg";
import fourtyOneImage from "../assets/gallery_Image/schoolImage(41).jpg";
import fourtyTwoImage from "../assets/gallery_Image/schoolImage(42).jpg";
import fourtyThreeImage from "../assets/gallery_Image/schoolImage(43).jpg";
import fourtyFourImage from "../assets/gallery_Image/schoolImage(44).jpg";
import fourtyFiveImage from "../assets/gallery_Image/schoolImage(45).jpg";
import fourtySixImage from "../assets/gallery_Image/schoolImage(46).jpg";
import fourtySevenImage from "../assets/gallery_Image/schoolImage(47).jpg";
import fourtyEightImage from "../assets/gallery_Image/schoolImage(48).jpg";
import fourtyNineImage from "../assets/gallery_Image/schoolImage(49).jpg";
import fiftyImage from "../assets/gallery_Image/schoolImage(50).jpg";
import fiftyOneImage from "../assets/gallery_Image/schoolImage(51).jpg";
import fiftyTwoImage from "../assets/gallery_Image/schoolImage(52).jpg";

import fiftyThreeImage from "../assets/gallery_Image/schoolImage(53).jpg";
import fiftyFourImage from "../assets/gallery_Image/schoolImage(54).jpg";
import fiftyFiveImage from "../assets/gallery_Image/schoolImage(55).jpg";
import fiftySixImage from "../assets/gallery_Image/schoolImage(56).jpg";
import fiftySevenImage from "../assets/gallery_Image/schoolImage(57).jpg";
import fiftyEightImage from "../assets/gallery_Image/schoolImage(58).jpg";
import fiftyNineImage from "../assets/gallery_Image/schoolImage(59).jpg";
import sixtyImage from "../assets/gallery_Image/schoolImage(60).jpg";
import sixtyOneImage from "../assets/gallery_Image/schoolImage(61).jpg";

import sixtyTwoImage from "../assets/gallery_Image/schoolImage(62).jpg";
import sixtyThreeImage from "../assets/gallery_Image/schoolImage(63).jpg";
import sixtyFourImage from "../assets/gallery_Image/schoolImage(64).jpg";
import sixtyFiveImage from "../assets/gallery_Image/schoolImage(65).jpg";
import sixtySixImage from "../assets/gallery_Image/schoolImage(66).jpg";
import sixtySevenImage from "../assets/gallery_Image/schoolImage(67).jpg";
import sixtyEightImage from "../assets/gallery_Image/schoolImage(68).jpg";
import sixtyNineImage from "../assets/gallery_Image/schoolImage(69).jpg";
import seventyImage from "../assets/gallery_Image/schoolImage(70).jpg";
import seventyOneImage from "../assets/gallery_Image/schoolImage(71).jpg";
import seventyTwoImage from "../assets/gallery_Image/schoolImage(72).jpg";
//import seventyThreeImage from "../assets/gallery_Image/schoolImage(73).jpg";

// import seventyFourImage from "../assets/gallery_Image/schoolImage(74).jpg";
// import seventyFiveImage from "../assets/gallery_Image/schoolImage(75).jpg";
import seventyThreeImage from "../assets/gallery_Image/schoolImage(73).jpg";
import seventyFourImage from "../assets/gallery_Image/schoolImage(74).jpg";
import seventyFiveImage from "../assets/gallery_Image/schoolImage(75).jpg";
import seventySixImage from "../assets/gallery_Image/schoolImage(76).jpg";
import seventySevenImage from "../assets/gallery_Image/schoolImage(77).jpg";
import seventyEightImage from "../assets/gallery_Image/schoolImage(78).jpg";
import seventyNineImage from "../assets/gallery_Image/schoolImage(79).jpg";
import EightyImage from "../assets/gallery_Image/schoolImage(80).jpg";
import EightyOneImage from "../assets/gallery_Image/schoolImage(81).jpg";
import EightyTwoImage from "../assets/gallery_Image/schoolImage(82).jpg";
import EightyThreeImage from "../assets/gallery_Image/schoolImage(83).jpg";
import EightyFourImage from "../assets/gallery_Image/schoolImage(84).jpg";
import EightyFiveImage from "../assets/gallery_Image/schoolImage(85).jpg";
import EightySixImage from "../assets/gallery_Image/schoolImage(86).jpg";


export interface CustomImage extends Image {
    original: string;
    order: number;
}

export const images: CustomImage[] = [
    {
        src: firstImage,
        original: firstImage,
        width: 320,
        height: 174,
        caption: "Welcome back to school Program photo !",
        order: 1
    }, {
        src: secondImage,
        original: secondImage,
        width: 320,
        height: 174,
        caption: "Teacher's staff meetings and discussion.",
        order: 2
    }
    , {
        src: thirdImage,
        original: thirdImage,
        width: 320,
        height: 174,
        caption: "Teacher's staff meetings and discussion.",
        order: 3
    }
    , {
        src: fourthImage,
        original: fourthImage,
        width: 320,
        height: 174,
        caption: "Welcome back to school Program photo !",
        order: 4
    }, {
        src: fifthImage,
        original: fifthImage,
        width: 320,
        height: 174,
        caption: "Maa Ahilya Devi ka malya arpan karte huye principal sir.",
        order: 5
    }, {
        src: sixthImage,
        original: sixthImage,
        width: 320,
        height: 174,
        caption: "Maa Saraswati ki pooja arpan karte huye principal & vice principal sir.",
        order: 6
    }, {
        src: seventhImage,
        original: seventhImage,
        width: 320,
        height: 174,
        caption: "Student at Welcome back to school Program photo !",
        order: 7
    }, {
        src: eighthImage,
        original: eighthImage,
        width: 320,
        height: 174,
        caption: "Vice Principal Sir",
        order: 8
    }, {
        src: ninthImage,
        original: ninthImage,
        width: 320,
        height: 174,
        caption: "Principal Sir",
        order: 9
    }, {
        src: tenthImage,
        original: tenthImage,
        width: 320,
        height: 174,
        caption: "Welcome back to school Program dance photo !",
        order: 10
    },
    {
        src: twelvethImage,
        original: twelvethImage,
        width: 320,
        height: 174,
        order: 11
    }, {
        src: thirteenthImage,
        original: thirteenthImage,
        width: 320,
        height: 174,
        order: 13
    }, {
        src: fourteenthImage,
        original: fourteenthImage,
        width: 320,
        height: 174,
        order: 14
    }, {
        src: fifteenthImage,
        original: fifteenthImage,
        width: 320,
        height: 174,
        order: 15
    },
    {
        src: sixteenthImage,
        original: sixteenthImage,
        width: 320,
        height: 174,
        order: 16
    },
    {
        src: seventeenthImage,
        original: seventeenthImage,
        width: 320,
        height: 174,
        order: 17
    },
    // Continue until the 35th image...
    {
        src: eighteenthImage,
        original: eighteenthImage,
        width: 320,
        height: 174,
        order: 18
    },
    {
        src: nineteenthImage,
        original: nineteenthImage,
        width: 320,
        height: 174,
        order: 19
    },
    {
        src: twentiethImage,
        original: twentiethImage,
        width: 320,
        height: 174,
        order: 20
    },
    // Continue until the 35th image...
    {
        src: twentyFirstImage,
        original: twentyFirstImage,
        width: 320,
        height: 174,
        order: 21
    },
    {
        src: twentySecondImage,
        original: twentySecondImage,
        width: 320,
        height: 174,
        order: 22
    },
    {
        src: twentyThirdImage,
        original: twentyThirdImage,
        width: 320,
        height: 174,
        order: 23
    },
    // Continue until the 35th image...
    {
        src: twentyFourthImage,
        original: twentyFourthImage,
        width: 320,
        height: 174,
        order: 24
    },
    {
        src: twentyFifthImage,
        original: twentyFifthImage,
        width: 320,
        height: 174,
        order: 26
    },
    {
        src: twentySixthImage,
        original: twentySixthImage,
        width: 320,
        height: 174,
        order: 26
    },

    {
        src: twentySeventhImage,
        original: twentySeventhImage,
        width: 320,
        height: 174,
        order: 27
    },
    {
        src: twentyEighthImage,
        original: twentyEighthImage,
        width: 320,
        height: 174,
        order: 28
    }
    ,
    {
        src: twentyNinthImage,
        original: twentyNinthImage,
        width: 320,
        height: 174,
        order: 29
    },
    {
        src: thirteenthImage,
        original: thirteenthImage,
        width: 320,
        height: 174,
        order: 30
    }
    ,
    {
        src: thirtyFourthImage,
        original: thirtyFourthImage,
        width: 320,
        height: 174,
        order: 34
    },
    {
        src: thirtySecondImage,
        original: thirtySecondImage,
        width: 320,
        height: 174,
        order: 32
    },
    {
        src: thirtyThirdImage,
        original: thirtyThirdImage,
        width: 320,
        height: 174,
        order: 33
    },
    {
        src: thirtyFivehImage,
        original: thirtyFivehImage,
        width: 320,
        height: 174,
        order: 35
    },
    {
        src: thirtySixImage,
        original: thirtySixImage,
        width: 320,
        height: 174,
        order: 36
    },
    {
        src: thirtySevenImage,
        original: thirtySevenImage,
        width: 320,
        height: 174,
        order: 37
    },
    {
        src: thirtyEightImage,
        original: thirtyEightImage,
        width: 320,
        height: 174,
        order: 38
    },
    {
        src: fourtyImage,
        original: fourtyImage,
        width: 320,
        height: 174,
        order: 40
    },
    {
        src: fourtyOneImage,
        original: fourtyOneImage,
        width: 320,
        height: 174,
        order: 41
    },
    {
        src: fourtyTwoImage,
        original: fourtyTwoImage,
        width: 320,
        height: 174,
        order: 42
    },
    {
        src: fourtyThreeImage,
        original: fourtyThreeImage,
        width: 320,
        height: 174,
        order: 43
    },
    {
        src: fourtyFourImage,
        original: fourtyFourImage,
        width: 320,
        height: 174,
        order: 44
    },
    {
        src: fourtyFiveImage,
        original: fourtyFiveImage,
        width: 320,
        height: 174,
        order: 45
    },
    {
        src: fourtySixImage,
        original: fourtySixImage,
        width: 320,
        height: 174,
        order: 46
    },
    {
        src: fourtySevenImage,
        original: fourtySevenImage,
        width: 320,
        height: 174,
        order: 47
    },
    {
        src: fourtyEightImage,
        original: fourtyEightImage,
        width: 320,
        height: 174,
        order: 48
    },
    {
        src: fourtyNineImage,
        original: fourtyNineImage,
        width: 320,
        height: 174,
        order: 49
    },
    {
        src: fiftyImage,
        original: fiftyImage,
        width: 320,
        height: 174,
        order: 50
    },
    {
        src: fiftyOneImage,
        original: fiftyOneImage,
        width: 320,
        height: 174,
        order: 51
    },
    {
        src: fiftyTwoImage,
        original: fiftyTwoImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftyThreeImage,
        original: fiftyThreeImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftyFourImage,
        original: fiftyFourImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftyFiveImage,
        original: fiftyFiveImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftySixImage,
        original: fiftySixImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftySevenImage,
        original: fiftySevenImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftyEightImage,
        original: fiftyEightImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: fiftyNineImage,
        original: fiftyNineImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyImage,
        original: sixtyImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyOneImage,
        original: sixtyOneImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyTwoImage,
        original: sixtyTwoImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyThreeImage,
        original: sixtyThreeImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyFourImage,
        original: sixtyFourImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyFiveImage,
        original: sixtyFiveImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtySixImage,
        original: sixtySixImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtySevenImage,
        original: sixtySevenImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: sixtyEightImage,
        original: sixtyEightImage,
        width: 320,
        height: 174,
        order: 52
    }
    ,
    {
        src: sixtyNineImage,
        original: sixtyNineImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyImage,
        original: seventyImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyOneImage,
        original: seventyOneImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyTwoImage,
        original: seventyTwoImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyThreeImage,
        original: seventyThreeImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyFourImage,
        original: seventyFourImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyFiveImage,
        original: seventyFiveImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventySixImage,
        original: seventySixImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventySevenImage,
        original: seventySevenImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyEightImage,
        original: seventyEightImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: seventyNineImage,
        original: seventyNineImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightyImage,
        original: EightyImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightyOneImage,
        original: EightyOneImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightyTwoImage,
        original: EightyTwoImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightyThreeImage,
        original: EightyThreeImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightyFourImage,
        original: EightyFourImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightyFiveImage,
        original: EightyFiveImage,
        width: 320,
        height: 174,
        order: 52
    },
    {
        src: EightySixImage,
        original: EightySixImage,
        width: 320,
        height: 174,
        order: 52
    },

    // {
    //     src: seventyFourImage,
    //     original: seventyFourImage,
    //     width: 320,
    //     height: 174,
    //     order: 52
    // },
    // {
    //     src: seventyFiveImage,
    //     original: seventyFiveImage,
    //     width: 320,
    //     height: 174,
    //     order: 52
    // }
];
