import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.css";
import logo from "../../assets/img/logo.png";
import {
    FaPhoneAlt,
    FaFacebookSquare,
   // FaTwitter,
    FaYoutube,
} from "react-icons/fa";
import { HiMailOpen, HiLocationMarker } from "react-icons/hi";

const Footer = () => {
    return (
        <div className={`bg-dark pt-4`}>
            <Container className="pl-0 pr-0">
                <Row className={`text-light mx-auto ${styles.Size}`}>
                    <Col>
                        <Row
                            className="mb-3"
                            style={{ borderBottom: "1px solid #fff" }}
                        >
                            <Col sm={4}>
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <img
                                            className={styles.Img}
                                            src={logo}
                                            alt="logo"
                                        />
                                    </Col>
                                    <Col>
                                        <h3 className="font-weight-bold">
                                            CM RISE Govt Ahilya Ashram School No 1
                                        </h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="text-center">
                                            
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-4" sm={4}>
                                <p className="font-weight-bold text-center">
                                    Contact Us
                                </p>
                                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                                    <FaPhoneAlt /> Telephone:  0731 2420366
                                </p>
                                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                                    <HiMailOpen /> Email:
                                    acmhss01@gmail.com
                                </p>
                                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                                    <HiLocationMarker /> Address: Pologround Industrial Estate, Indore Madhya Pradesh
                                </p>
                            </Col>
                            <Col className="mb-4" sm={4}>
                                <p className="font-weight-bold text-center">
                                    Follow Us
                                </p>
                                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                                    <FaFacebookSquare /> Facebook:{" "}
                                    <a
                                        className="text-info"
                                        href="https://www.facebook.com/profile.php?id=100092197583662"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        @CM RISE Govt. School
                                    </a>
                                </p>
                                {/* <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                                    <FaTwitter /> Twitter:{" "}
                                    <a
                                        className="text-info"
                                        href="https://twitter.com/shefalisen"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        @shefalisen
                                    </a>
                                </p> */}
                                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                                    <FaYoutube /> YouTube:{" "}
                                    <a
                                        className="text-info"
                                        href="https://www.youtube.com/@govt.ahilyaashramgirlshssn6281"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        @CM Rise Govt. Ahilya Ashram School No 1
                                    </a>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <p>
                                    Made with ❤️ by{" "}
                                    <a
                                        className="text-info"
                                        href="https://texterror.netlify.app/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        @TextError Technologies
                                    </a>{" "}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
