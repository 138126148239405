import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Facilities.module.css";
import { facilities as dataProfile, imgs } from "../../data/facilities";
import FacilitiesParsial from "./FacilitiesParsial";

const Facilities = () => {
  useEffect(() => {
    document.title = "Facilities | CM RISE Govt School Indore";
  }, []);

  const FacilitiesParsial1 = dataProfile
    .slice(0, dataProfile.length / 2)
    .map((item, idx) => {
      return (
        <FacilitiesParsial
          key={idx}
          type={idx % 2 === 0 ? 1 : 2}
          titleA={dataProfile[parseInt(idx * 2)].title}
          postA={dataProfile[parseInt(idx * 2)].content}
          titleB={dataProfile[parseInt(idx * 2 + 1)].title}
          postB={dataProfile[parseInt(idx * 2 + 1)].content}
          imgSrc={imgs[idx]}
        />
      );
    });

  return (
    <>
      <div className={`mb-5 ${styles.Bg}`}>
        <Container>
          <Row className="pt-4 text-light">
            <Col className={`${styles.Bottom}`}>
              <h1
                className={`
                                ${styles.Head} 
                                ${styles.Animated} 
                                ${styles.AnimatedFadeInUp} 
                                ${styles.FadeInUp}`}
              >
                Facilities
              </h1>
              <h1
                className={`
                                font-weight-bold 
                                ${styles.AnimatedChild} 
                                ${styles.AnimatedFadeInUp} 
                                ${styles.FadeInUp} 
                                ${styles.TitleFont}`}
              >
                CM RISE Govt Ahilya Ashram School No 1 Indore
              </h1>
            </Col>
          </Row>
        </Container>
        <div id={styles.DisplayScreen}>
          <h4 className={styles.FontColor}>Proposed Building</h4>
        </div>
      </div>
      {FacilitiesParsial1}
    </>
  );
};

export default Facilities;
