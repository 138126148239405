import { useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import styles from "../Home/Home.module.css";
import character_certificate from "../../assets/pdf_file/character_certificate.pdf";
import information_latter from "../../assets/pdf_file/information_letter.pdf";
import transfer_certificate from "../../assets/pdf_file/transfer_certificate.pdf";
import admission_form from "../../assets/pdf_file/admission_form.pdf";
import tc_request from "../../assets/pdf_file/tc_request.pdf";

import Button from "react-bootstrap/Button";

const Service = () => {
  useEffect(() => {
    document.title = "Services | CM RISE Govt School Indore";
  }, []);

  const donwloadPDF = (fileName) => {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = fileName;
    link.click();
  };

  return (
    <>
      <h2 className="font-weight-bold text-center mt-3">Download Forms</h2>
      <div className={styles.Dots}></div>
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Form Name</th>
              <th>Updated On</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Character Certificate</td>
              <td>{new Date().toLocaleString() + ""}</td>
              <td>
                <Button variant="primary" onClick={() => donwloadPDF(character_certificate)}>
                  Download
                </Button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Information Letter</td>
              <td>{new Date().toLocaleString() + ""}</td>
              <td>
              <Button variant="primary" onClick={() => donwloadPDF(information_latter)}>
                  Download
                </Button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Transfer Certificate</td>
              <td>{new Date().toLocaleString() + ""}</td>
              <td> <Button variant="primary" onClick={() => donwloadPDF(transfer_certificate)}>
                  Download
                </Button></td>
            </tr>
            <tr>
              <td>3</td>
              <td>Transfer Certificate Request Form </td>
              <td>{new Date().toLocaleString() + ""}</td>
              <td> <Button variant="primary" onClick={() => donwloadPDF(tc_request)}>
                  Download
                </Button></td>
            </tr>
            <tr>
              <td>3</td>
              <td>Admission Form</td>
              <td>{new Date().toLocaleString() + ""}</td>
              <td> <Button variant="primary" onClick={() => donwloadPDF(admission_form)}>
                  Download
                </Button></td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Service;
