import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./About.module.css";
import { profil as dataProfile, imgs } from "../../data/profil";
import ProfilParsial from "./ProfilParsial";

const About = () => {
  useEffect(() => {
    document.title = "About | CM RISE Govt School Indore";
  }, []);

  const profilParsial = dataProfile.slice(0, 5).map((item, idx) => {
    return (
      <ProfilParsial
        key={idx}
        type={idx % 2 === 0 ? 1 : 2}
         postA={dataProfile[parseInt(idx * 2)].content}
        // postB={dataProfile[parseInt(idx * 2 + 1)].content}
        imgSrc={imgs[idx]}
      />
    );
  });

  return (
    <>
      <div className={`mb-5 ${styles.Bg}`}>
        <Container>
          <Row className="pt-4 text-light">
            <Col className={`${styles.Bottom}`}>
              <h1
                className={`
                                ${styles.Head} 
                                ${styles.Animated} 
                                ${styles.AnimatedFadeInUp} 
                                ${styles.FadeInUp}`}
              >
                About Us
              </h1>
              <h1
                className={`
                                font-weight-bold 
                                ${styles.AnimatedChild} 
                                ${styles.AnimatedFadeInUp} 
                                ${styles.FadeInUp} 
                                ${styles.TitleFont}`}
              >
                CM RISE Govt Ahilya Ashram School No 1 Indore
              </h1>
            </Col>
          </Row>
        </Container>
        <div id={styles.DisplayScreen}>
          <h4 className={styles.FontColor}>Proposed Building</h4>
        </div>
      </div>
      {profilParsial}
    </>
  );
};

export default About;
