import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../Home/Home.module.css";

const AllPdfs = () => {
  const fullUrlKumodini = `https://flowpaper.com/flipbook/https://cmriseblob.blob.core.windows.net/pdfs/kumodini2nd.pdf`;
  const fullUrl = `https://flowpaper.com/flipbook/https://cmriseblob.blob.core.windows.net/pdfs/tejaswini.pdf`;

  useEffect(() => {
    document.title = "All Pdfs | CM RISE Govt School Indore";
  }, []);
  return (
    <Container>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="font-weight-bold text-center mt-3">All PDF Files</h2>
          <div className={styles.Dots}></div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <iframe
            title="pdfexample"
            src={fullUrlKumodini}
            width="100%"
            height="450"
            style={{ backgroundColor: "gray" }}
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <iframe
            title="pdfexample"
            src={fullUrl}
            allowFullScreen
            width="100%"
            height="450"
            style={{ backgroundColor: "gray" }}
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default AllPdfs;
