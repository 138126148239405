import { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styles from "../Home/Home.module.css";
import staffInfo from "../../data/staffInfo";
import staffStyles from "../Staff-Info/StaffInformation.module.css";

const StaffInfo = () => {
  useEffect(() => {
    document.title = "Staff Information | CM RISE Govt School Indore";
  }, []);

  let articles = staffInfo.map((staff) => {
    return (
      <Col sm={2}  className="mt-4" key={staff.id}>
        <Card border="light" className={`${styles.Hov}`}>
          <Card.Img style={{ width: '10rem', height: '10rem' }} className={styles.Img} src={staff.img} />
          <Card.Body>
            <Card.Title className="font-weight-bold text-dark">
              {staff.name}
            </Card.Title>
            <Card.Text className="font-weight-bold text-dark">
              {staff.position}
            </Card.Text>
            <Card.Text className="font-weight-bold text-dark">
              {staff.subject}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <>
      <div className={`mb-5 ${staffStyles.Bg}`}>
        <Container>
          <Row className="pt-4 text-light">
            <Col className={`${staffStyles.Bottom}`}>
              <h1
                className={`
                                ${staffStyles.Head} 
                                ${staffStyles.Animated} 
                                ${staffStyles.AnimatedFadeInUp} 
                                ${staffStyles.FadeInUp}`}
              >
                Staff Information
              </h1>
              <h1
                className={`
                                font-weight-bold 
                                ${staffStyles.AnimatedChild} 
                                ${staffStyles.AnimatedFadeInUp} 
                                ${staffStyles.FadeInUp} 
                                ${staffStyles.TitleFont}`}
              >
                CM RISE Govt Ahilya Ashram School No 1 Indore
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container>
          <Row className="mb-4 pb-4">{articles}</Row>
        </Container>
      </div>
    </>
  );
};

export default StaffInfo;
